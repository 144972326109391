import React, { useState, useEffect } from 'react';
// import Tooltip from 'components/Tooltip/Tooltip ';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import { useProSidebar } from "react-pro-sidebar";
const NewTooltip = ({ tooltiptext }) => {
  return (
    <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: '#000',
          color: 'white',
          fontSize: '12px',
          boxShadow: 3,
          borderRadius: "8px"
        },
      },
      arrow: {
        sx: {
          color: '#000',
        },
      },
    }}>
      <InfoOutlinedIcon sx={{ fontSize: "14px", }} />
    </Tooltip>
  );
};
const Substancesofconcernimpect = () => {
  const [rows, setRows] = useState([{ fieldName: '' }]);
  const { collapsed } = useProSidebar();
  const handleInputChange = (index, event) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { fieldName: '' }]);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };
  const Energy = [
    {
      value: "Surface-Water",
      label: "Surface Water",
    },
    {
      value: "Ground-water",
      label: "Ground water",
    },
    {
      value: "Sea-water",
      label: "Sea water",
    },
    {
      value: "Municipal-water",
      label: "Municipal water",
    },
    {
      value: "Third-party-water",
      label: "Third party water",
    },
    {
      value: "Other",
      label: "Other",
    },

  ];
  const Source = [
    {
      value: "Coal",
      label: "Coal",
    },
    {
      value: "Solar",
      label: "Solar",
    },
    {
      value: "LPG",
      label: "LPG",
    },
    {
      value: "Diesel",
      label: "Diesel",
    },
    {
      value: "Wind",
      label: "Wind",
    },
    {
      value: "Hydro",
      label: "Hydro",
    },
    {
      value: "Natural Gas",
      label: "Natural Gas",
    },
    {
      value: "Electricity",
      label: "Electricity",
    },
    {
      value: "Cooling",
      label: "Cooling",
    },
    {
      value: "Steam",
      label: "Steam",
    },
    {
      value: "Heating",
      label: "Heating",
    },
    {
      value: "Wood Chips",
      label: "Wood Chips",
    },
    {
      value: "Crude Oil",
      label: "Crude Oil",
    },
    {
      value: "Other",
      label: "Other",
    },

  ];
  const Unit = [
    {
      value: "Litre",
      label: "Litre",
    },
    {
      value: "Megalitre",
      label: "Megalitre",
    },
    {
      value: "Cubic-meter",
      label: "Cubic meter",
    },
    {
      value: "Kilolitre",
      label: "Kilolitre",
    },
    {
      value: "Million-litres-per-day",
      label: "Million litres  per day",
    },
    {
      value: "MMBtu",
      label: "MMBtu",
    },

  ];
  return (
    <>
      <div className="mt-3">
        <form>
          {rows.map((row, index) => (
            <div key={index} className="mb-4">
              <div className="flex mb-3">
                <div className={`overflow-x-scroll custom-scrollbar ${collapsed ? 'sm:w-[720px] md:w-[720px] lg:w-[720px] xl:w-[720px] 2xl:w-[720px]' : 'sm:w-[420px] md:w-[420px] lg:w-[620px] xl:w-[620px] 2xl:w-[620px]'}`}>
                  <div className="flex ">
                    <div className="w-full max-w-xs mb-3">
                    {index === 0 && (
                      <label className="text-sm leading-5 text-gray-700 flex h-[35px]">
                        Do you discharge any substances of concern
                        <div className="ml-2">

                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select Yes/No
                        </option>
                        <option>
                          Yes
                        </option>
                        <option>
                          No
                        </option>
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex h-[35px]">
                        Substance of concern
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Mention the substances of concern for which discharges are treated.
In the context of GRI Standard, substances of concern are
those that cause irreversible damage to the waterbody,
 ecosystem, or human health. For example: chemicals,
pollutants, heavy metals, contaminants or any toxic substances." />
                        </div>
                      </label>
                    )}
                      <input
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        placeholder="Enter the substance"
                      />
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex h-[35px]">
                        Method used to define priority
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Indicate how does the company define
the priority substances of concern" />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select the method
                        </option>
                        <option>international standard</option>
                        <option>authoritative list</option>
                        <option>others</option>
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex h-[35px]">
                        No of noncompliance incident
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Indicate the number of times the organization has engaged in unauthorized
 discharges (non-compliance incidents) exceeding compliance limits? (if any)" />
                        </div>
                      </label>
                    )}
                      <input
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        placeholder="Enter number"
                      />
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex h-[35px]">
                        Approach for setting discharge limits for priority substances of concern
                        <div className='ml-2'>
                          <NewTooltip tooltiptext="Provide a description of the approach used for setting discharge limits
for priority substances of concern." />
                        </div>
                      </label>
                    )}
                      <input
                        className="block w-[370px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        placeholder="Enter approach"
                      />
                    </div>
                  </div>
                </div>
               <div className={`flex pt-4 absolute  bg-white  h-[76px] ${collapsed ? 'sm:right-[4rem] md:right-[4rem] lg:right-[4rem] xl:right-[4rem] 2xl:right-[4rem] 3xl:right-[4rem]' :'sm:right-[3rem] md:right-[3rem] lg:right-[3rem] xl:right-[3rem] 2xl:right-[4rem] 3xl:right-[4rem]' }`}>
                  <div className="flex ml-3 h-[10px]">
                    <div className="w-[85px] h-[30px] px-2.5 py-1 bg-[#007EEF] rounded-l flex-col justify-center items-center inline-flex">
                      <div className="justify-center items-center gap-2 inline-flex">
                        <div className="relative text-white text-[13px] font-medium leading-snug tracking-wide">
                          Assign to
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-[#007EEF] rounded-r">
                      <div className="relative inline-flex">
                        <div className="absolute -mr-2 inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ArrowDropDownIcon className="text-white bg-[#007EEF]" />
                        </div>
                        <select className="flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-r cursor-pointer appearance-none focus:outline-none text-white bg-blue hover:bg-light-blue-300">
                          <option value="" className="text-white pe-1"></option>

                          <option>sk</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex ml-4 h-[10px]">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*" // Hide the file input
                    />
                    <button className="text-[#007EEF] text-[14px]">
                      <FileUploadOutlinedIcon
                        sx={{ mr: 0.5, color: "#007EEF", fontSize: "16px" }}
                      />{" "}
                      Upload
                    </button>
                  </div>

                  <div className="h-[10px] ml-4">
                    {" "}
                    <DeleteOutlineIcon
                      className="text-red-600 cursor-pointer"
                      onClick={() => removeRow(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </form>
      </div>
      <button
        className="font-bold py-2 px-4 rounded text-xs opacity-70 text-sky-600"
        onClick={addRow}
      >
        <AddOutlinedIcon style={{ fontSize: "20px", marginBottom: "3px" }} />{" "}
        Add new
      </button>
    </>
  );
};

export default Substancesofconcernimpect;
