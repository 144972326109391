import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
const Sourceoutside = () => {
    const { collapsed } = useProSidebar();
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-sm text-[#727272]'>Please mention the source of conversion factors used while compiling the information for Energy
                                Consumption outside the organization.</h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 302-2c</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>

            </div>
        </>
    );
}
export default Sourceoutside;