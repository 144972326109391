import React, { useState, useEffect } from "react";
// import Tooltip from 'components/Tooltip/Tooltip ';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import { useProSidebar } from "react-pro-sidebar";

const NewTooltip = ({ tooltiptext }) => {
  return (
    <Tooltip
      title={tooltiptext}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#000",
            color: "white",
            fontSize: "12px",
            boxShadow: 3,
            borderRadius: "8px",
          },
        },
        arrow: {
          sx: {
            color: "#000",
          },
        },
      }}
    >
      <InfoOutlinedIcon sx={{ fontSize: "14px" }} />
    </Tooltip>
  );
};
const Intensity = () => {
  const [rows, setRows] = useState([{ fieldName: "" }]);
  const {collapsed} = useProSidebar();
  const handleInputChange = (index, event) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { fieldName: "" }]);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };
  const Energy = [
    {
      value: "Heating",
      label: "Heating",
    },
    {
      value: "Cooling",
      label: "Cooling",
    },
    {
      value: "Steam",
      label: "Steam",
    },
    {
      value: "Electricity",
      label: "Electricity",
    },
    {
      value: "Fuel",
      label: "Fuel",
    },
    {
      value: "Energy-inside-the-organization-(302-1)",
      label: "Energy Inside The Organization (302-1)",
    },
    {
      value: "Energy-outside-the-organization-(302-1)",
      label: "Energy Outside The Organization (302-2)",
    },
    {
      value: "Total-Energy-(302-1+302-2)",
      label: "Total Energy (302-1+302-2)",
    },
    {
      value: "others",
      label: "Others",
    },
  ];
  const Source = [
    {
      value: "production-volume",
      label: "Production Volume",
    },
    {
      value:"size",
      label:"Size",
    },
    {
      value: "Number-of-Full-Time-Employees",
      label: "Number of Full Time Employees",
    },
    {
      value: "monetary-units-(such-as-revenu-or-sales)",
      label: "Monetary Units (Such As Revenue or Sales)",
    },
    {
      value: "production-units",
      label: "Production Units",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];
  const Unit = [
    {
      value: "Joules",
      label: "Joules",
    },
    {
      value: "KJ",
      label: "KJ",
    },
    {
      value: "Wh",
      label: "Wh",
    },
    {
      value: "KWh",
      label: "KWh",
    },
    {
      value: "GJ",
      label: "GJ",
    },
    {
      value: "MMBtu",
      label: "MMBtu",
    },
  ];
  const Munit = [
    {
    value: "Tonne",
    label: "Tonne",
    },
    {
    value: "meter-square",
    label: "Meter Square",
    },
    {
    value: "sales-unit",
    label: "Sales Unit",
    },
    {
    value: "liters",
    label: "Liters",
    },
    {
    value: "MWh",
    label: "MWh",
    },
    {
    value: "Revenue",
    label: "Revenue",
    },
    {
    value: "FTE",
    label: "FTE",
    },
    {
    value: "Others",
    label: "Others",
    },
  ];
  return (
    <>
       <div className="mt-3">
        <form>
          {rows.map((row, index) => (
            <div key={index} className="mb-4">
              <div className="flex mb-3">
                <div className={`overflow-x-scroll custom-scrollbar ${collapsed ? 'sm:w-[720px] md:w-[70%] lg:w-[75%] xl:w-[70%] 2xl:w-[80%] 3xl:w-[84%]' :'sm:w-[420px] md:w-[60%] lg:w-[65%] xl:w-[65%] 2xl:w-[74%] 3xl:w-[84%]' }`}>
                  <div className="flex ">
                    <div className="w-full max-w-xs mb-3">
                    {index === 0 && (
                      <label className="text-sm leading-5 text-gray-700 flex">
                        Energy Type
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Indicate the type of energy for which energy intensity
should be calculated" />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select energy type
                        </option>
                        {Energy.map((eng) => (
                          <>
                            <option value={eng.value}>{eng.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                      Energy Quantity
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Indicate the quantity of Energy Type" />
                        </div>
                      </label>
                    )}
                      <input
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        placeholder="Enter quantity"
                      />
                    </div>
                      <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Unit{" "}
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Select the correct unit corresponding to the quantity" />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select unit
                        </option>
                        {Unit.map((unit) => (
                          <>
                            <option value={unit.value}>{unit.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Organization metric
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Select the organization metric for the corresponding
Energy Intensity metric " />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select organization metric
                        </option>
                        {Source.map((source) => (
                          <>
                            <option value={source.value}>{source.label}</option>
                          </>
                        ))}
                      </select>
                    </div>

                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Metric quantity
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Indicate the quantity for Organization metric" />
                        </div>
                      </label>
                    )}
                      <input
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        placeholder="Enter quantity"
                      />
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Metric Unit{" "}
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Select the correct unit corresponding to the metric quantity." />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select unit
                        </option>
                        {Munit.map((munit) => (
                          <>
                            <option value={munit.value}>{munit.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className={`flex pt-4 absolute  bg-white  h-[73px] ${collapsed ? 'sm:right-[4rem] md:right-[4rem] lg:right-[4rem] xl:right-[4rem] 2xl:right-[4rem] 3xl:right-[4rem]' :'sm:right-[3rem] md:right-[3rem] lg:right-[3rem] xl:right-[3rem] 2xl:right-[4rem] 3xl:right-[4rem]' }`}>
                  <div className="flex ml-3 h-[10px]">
                    <div className="w-[85px] h-[30px] px-2.5 py-1 bg-[#007EEF] rounded-l flex-col justify-center items-center inline-flex">
                      <div className="justify-center items-center gap-2 inline-flex">
                        <div className="relative text-white text-[13px] font-medium leading-snug tracking-wide">
                          Assign to
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-[#007EEF] rounded-r">
                      <div className="relative inline-flex">
                        <div className="absolute -mr-2 inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ArrowDropDownIcon className="text-white bg-[#007EEF]" />
                        </div>
                        <select className="flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-r cursor-pointer appearance-none focus:outline-none text-white bg-blue hover:bg-light-blue-300">
                          <option value="" className="text-white pe-1"></option>

                          <option>sk</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex ml-4 h-[10px]">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*" // Hide the file input
                    />
                    <button className="text-[#007EEF] text-[14px]">
                      <FileUploadOutlinedIcon
                        sx={{ mr: 0.5, color: "#007EEF", fontSize: "16px" }}
                      />{" "}
                      Upload
                    </button>
                  </div>

                  <div className="h-[10px] ml-4">
                    {" "}
                    <DeleteOutlineIcon
                      className="text-red-600 cursor-pointer"
                      onClick={() => removeRow(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </form>
      </div>
      <button
        className="font-bold py-2 px-4 rounded text-xs opacity-70 text-sky-600"
        onClick={addRow}
      >
        <AddOutlinedIcon style={{ fontSize: "20px", marginBottom: "3px" }} />{" "}
        Add new
      </button>
    </>
  );
};

export default Intensity;
