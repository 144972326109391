import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';

const ImageUpload = ({onFileSelect}) => {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTimeoutId, setErrorTimeoutId] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]; // Handling single file
    if (!file) return; // If no file, do nothing

    if (file) {
      onFileSelect(file);
    }

    // Check for file size limit (2MB)
    if (file.size > 2097152) {
      const newErrorMessage = "File size should not exceed 2MB.";
      setErrorMessage(newErrorMessage);
      const timeoutId = setTimeout(() => {
        setErrorMessage('');
        setErrorTimeoutId(null);
      }, 10000); // Clear the error after 20 seconds
      setErrorTimeoutId(timeoutId);
      return;
    }
    setIsUploading(true); // Start the upload indicator

    // Setup file reader for image preview (if image)
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      setFiles([{
        name: file.name,
        size: file.size,
        type: file.type,
        preview: reader.result
      }]);
    };

    if (file.type.startsWith('image/')) {
      reader.readAsDataURL(file);
    } else {
      setFiles([{
        name: file.name,
        size: file.size,
        type: file.type,
        preview: null
      }]);
    }

    mockUpload(file);
  }, [onFileSelect]);

  const mockUpload = (file) => {
    // Simulate an upload process
    const uploadInterval = setInterval(() => {
      setUploadProgress(prevProgress => {
        const progress = (prevProgress[file.name] || 0) + 10;
        if (progress >= 100) {
          clearInterval(uploadInterval);
          setIsUploading(false);
        }
        return { ...prevProgress, [file.name]: progress };
      });
    }, 500);
  };

  const removeFile = (fileName) => {
    setFiles([]); // Clear the file list
    setUploadProgress({}); // Reset upload progress
    setIsUploading(false); // Reset uploading state
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // const getFileIcon = (fileType) => {
  //   if (fileType.startsWith('image/')) {
  //     return '🖼️';
  //   } else if (fileType === 'application/pdf') {
  //     return <FilePresentOutlinedIcon sx={{color:"#28C1A2", fontSize:'22px'}}/>;
  //   } else {
  //     return '📁';
  //   }
  // };

  return (
    <div className="container mx-auto">
      {files.length === 0 && (
        <div {...getRootProps()} className="border-dashed border-2 border-blue-200 py-[30px] px-[40px] text-center cursor-pointer bg-[#007eef0d] gap-8 rounded-md">
          <input {...getInputProps()} />
          <div className='flex justify-center tracking-wide font-bold mb-4'>
<FileUploadOutlinedIcon/>
          <p className='ml-5'>Drag & drop files or <span className='text-[#0057A5] font-bold'>Browse</span></p>
          </div>
          <div className='flex-col justify-center tracking-wide text-gray-400 text-[13px]'>
            <p> Supported formats: JPEG, PNG, PDF, Word</p>
            <p> Max file size : 1MB</p>
          </div>

        </div>
      )}
    {errorMessage && (
        <p className="text-red-500 text-center">{errorMessage}</p>
      )}
      <div className="mt-4 space-y-4">
        {files.map((file, index) => (
          <div key={index} className="relative px-8 py-2 text-black rounded-md  border flex items-center">

<div className="flex">
            <div className="">

                <span><FilePresentOutlinedIcon sx={{color:"#28C1A2", fontSize:'22px'}}/></span>

            </div>
              <div className='ml-2'>
                <p className="text-[14px] truncate w-48"> {file.name}</p>
                <p className="text-[12px] text-gray-400">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                </div>

            </div>



            <button onClick={() => removeFile(file.name)} className="ml-auto  p-2 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
  <circle cx="16" cy="16.0175" r="16" fill="#FFF3F3"/>
  <path d="M22.9538 10.3078H20.9547C20.928 10.2985 20.9002 10.2925 20.872 10.2897H18.7435V8.52441C18.7436 8.39174 18.6916 8.26438 18.5989 8.16962C18.5061 8.07488 18.3799 8.02022 18.2474 8.01746H13.2134C13.079 8.01746 12.95 8.07079 12.855 8.16589C12.7599 8.261 12.7065 8.38993 12.7065 8.52441V10.2897H10.5888C10.5606 10.2925 10.5328 10.2985 10.5061 10.3078H8.50694C8.32576 10.3078 8.15841 10.4043 8.0679 10.5612C7.97737 10.7181 7.97737 10.9113 8.0679 11.0681C8.15843 11.225 8.32576 11.3217 8.50694 11.3217H10.1357L11.4517 23.5645C11.4658 23.6891 11.5253 23.804 11.6188 23.8877C11.7122 23.9712 11.8332 24.0173 11.9586 24.0175H19.5092C19.6334 24.0157 19.7525 23.9686 19.8447 23.8853C19.9366 23.8018 19.995 23.6878 20.009 23.5644L21.3249 11.3396H22.9537C23.1349 11.3396 23.3023 11.243 23.3928 11.0861C23.4833 10.9293 23.4833 10.7359 23.3928 10.5792C23.3023 10.4223 23.1349 10.3257 22.9537 10.3257L22.9538 10.3078ZM13.7313 9.03135H17.7294V10.2897H13.7313V9.03135ZM19.0491 22.9963H12.4116L11.1533 11.3397H20.3074L19.0491 22.9963Z" fill="#D64564"/>
  <path d="M15.7315 21.8098C15.866 21.8098 15.995 21.7564 16.0901 21.6614C16.1852 21.5663 16.2385 21.4374 16.2385 21.3029V13.0333C16.2385 12.8521 16.1419 12.6847 15.9851 12.5942C15.8282 12.5036 15.6349 12.5036 15.478 12.5942C15.3212 12.6848 15.2246 12.8521 15.2246 13.0333V21.3029C15.2246 21.4374 15.2779 21.5663 15.373 21.6614C15.4681 21.7564 15.5971 21.8098 15.7316 21.8098H15.7315Z" fill="#D64564"/>
  <path d="M13.7348 21.8063H13.7563C13.8902 21.8008 14.0164 21.7424 14.1074 21.6441C14.1983 21.5457 14.2467 21.4153 14.2418 21.2814L13.9361 13.0118L13.9362 13.0117C13.9334 12.8763 13.8755 12.7477 13.7757 12.656C13.6758 12.5644 13.5429 12.5175 13.4076 12.5263C13.2737 12.532 13.1476 12.5904 13.0567 12.6887C12.9657 12.7871 12.9174 12.9174 12.9222 13.0513L13.2279 21.3209C13.2342 21.4513 13.2903 21.5744 13.3847 21.6646C13.479 21.7548 13.6043 21.8056 13.7348 21.8063L13.7348 21.8063Z" fill="#D64564"/>
  <path d="M17.7084 21.8056H17.7264C17.86 21.8086 17.9894 21.759 18.0869 21.6677C18.1843 21.5762 18.2421 21.4501 18.2477 21.3166L18.5534 13.047H18.5533C18.5582 12.9131 18.5098 12.7827 18.4189 12.6844C18.3279 12.586 18.2018 12.5276 18.0679 12.522C17.931 12.5079 17.7945 12.5521 17.6918 12.6438C17.5891 12.7354 17.5299 12.8662 17.5285 13.0039L17.2193 21.2735H17.2194C17.2125 21.4092 17.2604 21.542 17.3524 21.642C17.4443 21.742 17.5726 21.801 17.7084 21.8056L17.7084 21.8056Z" fill="#D64564"/>
</svg>
            </button>
            {isUploading && (
              <div className="absolute bottom-0 left-0 right-0 bg-gray-700 rounded-b-md h-1">
                <div className="bg-gradient-to-r from-blue-500 to-[#6ADF23] h-1 rounded-b-md" style={{ width: `${uploadProgress[file.name]}%` }}></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUpload;
