import { useState, useEffect } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { Energydata } from "components/data/info/energydata";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { setIsGRIBoxOpen } from "state";
import { useDispatch, useSelector } from "react-redux";

const Header = ({ activeTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [data, setData] = useState();
  const isGRIBoxOpen = useSelector((state) => state.global.isGRIBoxOpen);
  const dispatch = useDispatch();


  const { collapsed } = useProSidebar();

  const toggleDrawerclose = () => {
    setIsOpen(!isOpen);
    dispatch(setIsGRIBoxOpen(!isGRIBoxOpen));
  };
  const toggleDrawer = (selected) => {
    setIsOpen(!isOpen);
    dispatch(setIsGRIBoxOpen(!isGRIBoxOpen));
    setCategory(selected);
  };

  useEffect(() => {
    var newData = [];
    Energydata.map((program) => {
      program.category.map((tag) => {
        if (tag === category) {
          newData.push(program);
        }
      });
    });
    // //console.log(newData);
    setData(newData);
  }, [category]);

  return (
    <div className="flex justify-between items-center border-b border-gray-200 pb-4 z-100">
      <div className="h-[46px] flex-col justify-start items-start gap-0.5 inline-flex ms-8 mt-8">
        <div className="text-black text-opacity-50 text-[11px] font-semibold font-['Manrope'] capitalize leading-[14px]">
          Environment
        </div>
        <div className="h-[30px]">
          <div className="h-[26px] flex justify-start items-end gap-3">
            <div className="gradient-text text-opacity-20 text-[22px] font-medium font-['Manrope'] leading-[1.425rem]">
              {activeTab}
            </div>
            <div className="w-[95px] pl-1 pr-0.5 bg-slate-200 rounded justify-center items-center flex">
              <div className="text-zinc-600 text-xs font-normal font-['Manrope'] leading-[21px]">
                Material Topic
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        activeTab === 'Emissions' &&
        <div className="flex flex-col border-l gap-2 absolute right-12 top-8">
       <div className="flex">
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("44")}
          >
            GRI 305-1
          </button>
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("45")}
          >
            GRI 305-2
          </button>
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("46")}
          >
            GRI 305-3
          </button>

        </div>
        <div className="flex">
          <button
            className="text-[#fff] bg-[#4C9F38] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 "
            onClick={() => toggleDrawer("19")}
          >
            SDG 3
          </button>
          <button
            className="text-[#fff] bg-[#BF8B2E] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("30")}
          >
            SDG 12
          </button>
          <button
            className="text-[#fff] bg-[#3F7E44] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 "
            onClick={() => toggleDrawer("5")}
          >
            SDG 13
          </button>
          <button
            className="text-[#fff] bg-[#007DBC] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("sd6")}
          >
            SDG 14
          </button>
          <button
            className="text-[#fff] bg-[#40AE49] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("sd7")}
          >
            SDG 15
          </button>
        </div>

      </div>
      }
      {
        activeTab === 'Energy' &&
        <div className="flex flex-col border-l gap-2 absolute right-12 top-8">
        <div className="flex">
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("47")}
          >
            GRI 302-1
          </button>
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("48")}
          >
            GRI 302-2
          </button>
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("49")}
          >
            GRI 302-3
          </button>
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("50")}
          >
            GRI 302-4
          </button>
          <button
            className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("51")}
          >
            GRI 302-5
          </button>
        </div>
        <div className="flex">
          <button
            className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 "
            onClick={() => toggleDrawer("sd1")}
          >
            SDG 7
          </button>
          <button
            className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("sd2")}
          >
            SDG 8
          </button>
          <button
            className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 "
            onClick={() => toggleDrawer("sd3")}
          >
            SDG 12
          </button>
          <button
            className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5"
            onClick={() => toggleDrawer("sd4")}
          >
            SDG 13
          </button>
        </div>
      </div>
      }
      <div
        className={`${
          isOpen ? "translate-x-[15%] block mt-[56px]" : "translate-x-[120%] hidden"
        }
      fixed right-[51px] top-0 w-[340px] h-full bg-white  rounded-md
      transition-transform duration-300 ease-in-out z-[100] shadow-2xl px-2`}
      >
        {data &&
          data.map((program) => (
            <>
              <div className="flex justify-between p-2 pt-5 pb-4 border-b-2 ">
                <div className="ml-2">{program.header}</div>

                <div className="ml-2 float-right">
                  <h5
                    className="text-[#727272] text-[17px] font-bold cursor-pointer"
                    onClick={toggleDrawerclose}
                  >
                    <ClearOutlinedIcon />
                  </h5>
                </div>
              </div>
              <div> {program.data}</div>
            </>
          ))}
      </div>
    </div>
  );
};

export default Header;
