import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  FilePresent,
  ArrowDropDown,
  KeyboardArrowDownOutlined,
  DeleteOutline,
  MoreVert,
  EditOutlined,
  SaveOutlined,
  DoneAllOutlined,
  CheckOutlined,
  FileUploadOutlined,
  CheckBoxOutlined,
  CircleRounded,
} from "@mui/icons-material";
import PdfPreviewModal from "./PdfPreviewModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setRefreshPage } from "state/emissionSlice";
import ConfirmationModal from "components/react-modal/confirmModal";
import { unitTypes as units } from "components/data/units";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FileIconExcel from "./FileIcon";
import { Tooltip } from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";

function ShowEmission({ data, scope, scopeInfo }) {
  //loader
  const [loopen, setLoOpen] = useState(false);
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const [rows, setRows] = useState(data);
  const [uploadedFile, setUploadedFile] = useState(null); // New state variable to store uploaded file
  const refreshPage = useSelector((state) => state.emission.refreshPage);
  const dispatch = useDispatch();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handleRowDelete = (id) => {
    LoaderOpen();
    setRowToDelete(id);
    setIsDeleteConfirmationOpen(true);
    confirmDelete();
    LoaderClose();
  };

  const confirmDelete = () => {
    if (rowToDelete) {
      axios
        .delete(`${process.env.REACT_APP_BACKEND_URL}/emissions/${rowToDelete}`)
        .then(() => {
          console.log("Deletion was successful");
          // Handle any other post-deletion logic here
        })
        .catch((error) => {
          console.error("Error deleting row:", error);
        })
        .finally(() => {
          setIsDeleteConfirmationOpen(false);
          setRowToDelete(null);
          dispatch(setRefreshPage(!refreshPage));
        });
    }
  };

  const closeDeleteConfirmation = () => {
    setRowToDelete(null);
    setIsDeleteConfirmationOpen(false);
  };

  const [showDropdown, setShowDropdown] = useState(
    new Array(data?.length).fill(false)
  );

  useEffect(() => {
    setShowDropdown(new Array(data?.length).fill(false));
  }, [data]);

  const toggleRowDropdown = (index) => {
    const updatedShowDropdown = [...showDropdown];
    updatedShowDropdown[index] = !updatedShowDropdown[index];
    setShowDropdown(updatedShowDropdown);
  };

  const dropdownRef = useRef(null);

  const [editRowIndex, setEditRowIndex] = useState(-1);
  const [editRow, setEditRow] = useState({
    id: "",
    value1: null,
    unit_type: "",
    unit1: "",
    value2: null,
    unit2: "",
    assign_to: "",
    sector: "",
    category: "",
    file: "",
    fileName: "",
    modifiedTime: "",
    activity_rowbatchdata: {
      name: "",
      activity_id: "",
      emmissionfactorid: "",
      id: "",
    },
  });

  const handleUpdateClick = (index, row) => {
    setEditRowIndex(index);
    toggleRowDropdown(index);
    // console.log('row in update: ' + JSON.stringify(row));
    setEditRow({
      id: row.id,
      value1: row.value1,
      unit_type: row.unitType,
      unit1: row.unit[0],
      value2: row.value2,
      unit2: row.unit[1],
      assign_to: row.assignTo,
      sector: row.category,
      category: row.subCategory,
      file: row.file,
      fileName: row.fileName,
      modifiedTime: row.modifiedTime,
      activity_rowbatchdata: {
        name: row.name,
        activity_id: row.activity_id,
        emmissionfactorid: row.emmissionfactorid,
        id: row.emmissionfactorid,
      },
    });
  };

  const handleChange = (event, field) => {
    console.log("handleChange triggered", scope);
    const newEditRow = { ...editRow };

    if (field === "assignTo") {
      newEditRow.assign_to = event.target.value;
    } else if (field === "unit1") {
      newEditRow.unit1 = event.target.value;
    } else if (field === "unit2") {
      newEditRow.unit2 = event.target.value;
    } else if (field === "value1") {
      newEditRow.value1 = event.target.value;
    } else if (field === "value2") {
      newEditRow.value2 = event.target.value;
    } else if (field === "file") {
      const uploadedFile = event.target.files[0];
      console.log("uploaded File", uploadedFile);
      if (uploadedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = (event) => {
          newEditRow.file = event.target.result;
          newEditRow.fileName = uploadedFile.name;
          newEditRow.modifiedTime = new Date().toLocaleString();
          newEditRow.uploadedBy = localStorage.getItem("email");
          setEditRow(newEditRow);
          setShowCheckmark(true);
          setTimeout(() => {
            setShowCheckmark(false);
          }, 2000);
        };
      }
    }

    setEditRow(newEditRow);
  };

  const saveHandler = () => {
    LoaderOpen();
    // Construct the payload
    const payload = {
      id: editRow.id,
      value1: parseFloat(editRow.value1),
      unit_type:
        editRow.unit_type.charAt(0).toUpperCase() + editRow.unit_type.slice(1),
      unit1: editRow.unit1,
      assign_to: editRow.assign_to,
      sector: editRow.sector,
      category: editRow.category,
      file: editRow.file,
      fileName: editRow.fileName,
      modifiedTime: editRow.modifiedTime,
      activity_rowbatchdata: {
        name: editRow.activity_rowbatchdata?.name,
        activity_id: editRow.activity_rowbatchdata.activity_id,
        emmissionfactorid: editRow.activity_rowbatchdata.emmissionfactorid,
        id: editRow.activity_rowbatchdata.id,
      },
    };

    if (editRow.value2) {
      payload.value2 = parseFloat(editRow.value2);
      payload.unit2 = editRow.unit2 || "";
    }

    // console.log({ payload });
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/emissions/${payload.id}/`,
        payload
      )
      .then((response) => {
        console.log("Update successful:", response.data, editRow);
        LoaderClose();
      })
      .catch((error) => {
        LoaderClose();
        console.error("Error updating data:", error, editRow);
      })
      .finally(() => {
        setEditRowIndex(-1);
        dispatch(setRefreshPage(!refreshPage));
      });
  };

  // Preview

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewRowIndex, setPreviewRowIndex] = useState(null);
  const [iframePreview, setIframePreview] = useState(false);

  const handleGreenIconClick = (index, source) => {
    console.log("handleGreenIconClick triggered", index, source);
    if (source === "Approved") {
      setIframePreview(true);
    } else if (source === "not defined") {
      toast.error("Preview not available", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setIframePreview(false);
      setPreviewRowIndex(index);
      setShowPreviewModal(true);
    }
  };

  const closeModal = () => {
    setShowPreviewModal(false);
  };

  // Decode file

  function base64ToRawFile(base64String, fileName) {
    // Decode the base64 string into a binary data buffer
    const binaryString = atob(base64String);

    // Create a Uint8Array from the binary data
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([uint8Array], { type: "application/octet-stream" });

    // Create a download link for the Blob
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Trigger a click event to download the file
    link.click();
  }

  const [showCheckmark, setShowCheckmark] = useState(false);
  const generateInputId = (rowId, index) => {
    return `fileInput-${scope}-${rowId}-${index}`;
  };

  const handleFileDelete = (index) => {
    if (editRowIndex === index) {
      setEditRow((prev) => ({
        ...prev,
        file: null,
        fileName: null,
        modifiedTime: null,
        uploadedBy: null,
      }));
    } else {
      const newRows = [...rows];
      newRows[index] = {
        ...newRows[index],
        file: null,
        fileName: null,
        modifiedTime: null,
        uploadedBy: null,
      };
      setRows(newRows);
    }
    setShowPreviewModal(false);
  };

  // Reset file input field value after each change to ensure re-triggering
  const handleFileInputReset = (inputElement) => {
    inputElement.value = null;
  };

  useEffect(() => {
    console.log("editRow changed");
  }, [
    editRow.file,
    editRow.fileName,
    editRow.modifiedTime,
    editRow.uploadedBy,
  ]);

  return (
    data && (
      <>
        {data?.map((row, index) => (
          <tr key={index} className="border-b border-[#EDEAE9] relative h-11">
            <td className="w-[8px] py-2 text-left">
              <CircleRounded style={{ color: "green", fontSize: "8px" }} />
            </td>
            <td className="w-[17.25%]">
              <div className="relative">
                <select
                  className="cursor-pointer appearance-none bg-white py-2 rounded leading-tight outline-none m-[3px] w-full truncate"
                  disabled={editRowIndex !== index}
                >
                  <option value="">{row.category}</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                  <KeyboardArrowDownOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "20px" }}
                  />
                </div>
              </div>
            </td>
            <td className="w-[17.25%]">
              <div className="relative">
                <select
                  className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full truncate"
                  disabled={editRowIndex !== index}
                >
                  <option value="">{row.subCategory}</option>
                </select>
                <div className="absolute inset-y-0 -right-2 flex items-center pointer-events-none">
                  <KeyboardArrowDownOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "20px" }}
                  />
                </div>
              </div>
            </td>
            <td className="w-[17.25%]">
              <div className="relative">
                <select
                  className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full"
                  disabled={editRowIndex !== index}
                >
                  <option value="">
                    {row.activity_name} - {row.unitType}
                  </option>
                </select>
                <div className="absolute inset-y-0 -right-2 flex items-center pointer-events-none">
                  <KeyboardArrowDownOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "20px" }}
                  />
                </div>
              </div>
            </td>

            {!row?.unitType?.includes("over") ? (
              <td className="relative w-[27.21%]">
                <div className="flex w-full">
                  <div className="flex-grow">
                    <input
                      type="number"
                      step="0.01"
                      className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                      value={
                        editRowIndex === index ? editRow.value1 : row.value1
                      }
                      onChange={(event) => handleChange(event, "value1")}
                      disabled={editRowIndex !== index}
                    />
                  </div>
                  <div className="absolute right-0 top-0.5">
                    <select
                      value={
                        editRowIndex === index ? editRow.unit1 : row.unit[0]
                      }
                      onChange={(event) => handleChange(event, "unit1")}
                      className={`cursor-pointer appearance-none px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                        row.unit[0]
                          ? "text-sky-600 bg-white drop-shadow-md"
                          : "bg-sky-600 text-white"
                      }`}
                      style={{ width: "66px" }}
                      disabled={editRowIndex !== index}
                    >
                      <option className="text-xs">{row.unit[0]}</option>
                      {row.unitType &&
                        units
                          .filter(
                            (unit) =>
                              unit.unit_type ===
                              row.unitType.charAt(0).toUpperCase() +
                                row.unitType.slice(1)
                          )
                          .reduce((combinedUnits, unit) => {
                            return combinedUnits.concat(
                              Object.values(unit.units)
                            );
                          }, [])
                          .flat()
                          .map((unitName) => (
                            <option key={unitName} className="text-xs">
                              {unitName}
                            </option>
                          ))}
                    </select>
                    <span className="absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none">
                      <ArrowDropDown
                        className={`text-xs ${
                          row.unit[0] ? "text-sky-600" : "text-white "
                        }`}
                      />
                    </span>
                  </div>
                </div>
              </td>
            ) : (
              <td className="w-[27.21%]">
                <div className="flex items-center">
                  <div className="relative w-1/2">
                    <input
                      type="number"
                      step="0.01"
                      value={
                        editRowIndex === index ? editRow.value1 : row.value1
                      }
                      onChange={(event) => handleChange(event, "value1")}
                      className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                      disabled={editRowIndex !== index}
                    />
                    <div className="absolute right-0 top-0.5">
                      <select
                        value={
                          editRowIndex === index ? editRow.unit1 : row.unit[0]
                        }
                        onChange={(event) => handleChange(event, "unit1")}
                        className={`cursor-pointer appearance-none px-2 pe-5 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                          row.unit[0]
                            ? "text-sky-600 bg-white drop-shadow-md"
                            : "bg-sky-600 text-white"
                        } w-[66px]`}
                        style={{ width: "66px" }}
                        disabled={editRowIndex !== index}
                      >
                        <option className="text-xs truncate">
                          {row.unit[0]}
                        </option>
                        {units
                          .filter((unit) => {
                            {
                              /* console.log('row.unitType:', row.unitType);
                                console.log('unit.unit_type:', unit.unit_type); */
                            }
                            return (
                              unit.unit_type.toLowerCase() ===
                              row.unitType.toLowerCase()
                            );
                          })
                          .map((unit) => {
                            const unitValues = Object.values(unit.units);
                            if (unitValues.length >= 2) {
                              const firstArray = unitValues[0];
                              return firstArray;
                            }
                            return [];
                          })
                          .flat()
                          .map((unitName) => (
                            <option key={unitName} className="text-xs truncate">
                              {unitName}
                            </option>
                          ))}
                      </select>

                      <span className="absolute right-0 top-[1.15rem] transform -translate-y-1/2 pointer-events-none">
                        <ArrowDropDown
                          className={`text-xs ${
                            row.unit[0] ? "text-sky-600" : "text-white "
                          }`}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="relative w-1/2 ">
                    <input
                      type="number"
                      step="0.01"
                      className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                      value={
                        editRowIndex === index ? editRow.value2 : row.value2
                      }
                      onChange={(event) => handleChange(event, "value2")}
                      disabled={editRowIndex !== index}
                    />
                    <div className="absolute right-0 top-0.5">
                      <select
                        value={
                          editRowIndex === index ? editRow.unit2 : row.unit[0]
                        }
                        onChange={(event) => handleChange(event, "unit2")}
                        className={`cursor-pointer appearance-none  px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                          row.unit[1]
                            ? "text-sky-600 bg-white drop-shadow-md"
                            : "bg-sky-600 text-white"
                        }`}
                        style={{ width: "66px" }}
                        disabled={editRowIndex !== index}
                      >
                        <option className="text-xs">{row.unit[1]}</option>
                        {units
                          .filter(
                            (unit) =>
                              unit.unit_type.toLowerCase() ===
                              row.unitType.toLowerCase()
                          )
                          .map((unit) => {
                            const unitValues = Object.values(unit.units);
                            if (unitValues.length >= 2) {
                              return unitValues[1]; // Get the second array within units
                            }
                            return [];
                          })
                          .flat()
                          .map((unitName) => (
                            <option key={unitName} className="text-xs">
                              {unitName}
                            </option>
                          ))}
                      </select>
                      <span className="absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none">
                        <ArrowDropDown
                          className={`text-xs ${
                            row.unit[1] ? "text-sky-600" : "text-white "
                          }`}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            )}
            <td className="w-[118px]">
              <div className="flex ml-2">
                <div
                  className={`w-[112px] h-[30px] pr-2.5 py-1 ${
                    row.assignTo == ""
                      ? "bg-sky-600 text-white"
                      : "text-[#0056a4] bg-white drop-shadow-md"
                  } rounded-md border shadow-lg flex-col justify-center items-center inline-flex overflow-x-hidden`}
                  // onClick={() => openModal(row, index)}
                >
                  <div
                    className={`justify-start items-center gap-2 inline-flex`}
                  >
                    {row.assignTo == "" ? (
                      <div className="relative left-0 text-[13px] font-medium leading-snug tracking-wide cursor-pointer">
                        {" "}
                        Assign To
                      </div>
                    ) : (
                      <div className="relative w-[85px] left-1 text-[13px] font-medium leading-snug tracking-wide cursor-pointer">
                        {row.assignTo}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </td>
            <td className="w-[8.70%] relative">
              <div className="flex items-center space-x-1.5 ml-3">
                {editRowIndex === index ? (
                  <SaveOutlined
                    className="cursor-pointer hover:text-stone-400"
                    onClick={saveHandler}
                  />
                ) : (
                  <MoreVert
                    className="cursor-pointer"
                    onClick={() => toggleRowDropdown(index)}
                  />
                )}
                <div className="relative">
                  <label
                    htmlFor={`fileInput-${index}-${scope}-${row.id}`}
                    className={`absolute left-2 top-0 scope-${scope}`}
                  >
                    <div className="flex items-center justify-center h-10">
                      {showCheckmark ? (
                        <CheckOutlined className="w-6 h-6 text-green-500" />
                      ) : editRowIndex === index ? (
                        editRow.file ? (
                          <div
                            className="flex items-center"
                            onClick={() =>
                              handleGreenIconClick(
                                index,
                                editRow.rowSource || row.rowSource
                              )
                            }
                          >
                            <div>
                              <a
                                data-tooltip-id="filename-tooltip"
                                data-tooltip-html={
                                  editRow.fileName || row.fileName
                                }
                                data-tooltip-variant="dark"
                              >
                                <FileIconExcel
                                  className="w-6 h-6 mr-1 text-gray-400 cursor-pointer"
                                  style={{ color: "green" }}
                                />
                              </a>
                              <Tooltip
                                id="filename-tooltip"
                                className="absolute rounded py-1 px-2 leading-5"
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`${
                              (editRowIndex !== index && row.file) ||
                              (editRowIndex === index && editRow.file)
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          >
                            <FileUploadOutlined className="w-6 h-6 text-sky-600 hover:text-gray-700 cursor-pointer" />
                          </div>
                        )
                      ) : row.file ? (
                        <div
                          className="flex items-center"
                          onClick={() =>
                            handleGreenIconClick(index, row.rowSource)
                          }
                        >
                          <div>
                            <a
                              data-tooltip-id="filename-tooltip"
                              data-tooltip-html={row.fileName}
                              data-tooltip-variant="dark"
                            >
                              <FileIconExcel
                                className="w-6 h-6 mr-1 text-gray-400"
                                style={{ color: "green" }}
                              />
                            </a>
                            <Tooltip
                              id="filename-tooltip"
                              className="absolute rounded py-1 px-2 leading-5"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="cursor-not-allowed">
                          <FileUploadOutlined className="w-6 h-6 text-sky-600 hover:text-gray-700 cursor-not-allowed" />
                        </div>
                      )}
                    </div>
                  </label>
                  <input
                    id={`fileInput-${index}-${scope}-${row.id}`}
                    type="file"
                    className={`hidden scope-${scope}`}
                    onChange={(event) => {
                      handleChange(event, "file");
                      handleFileInputReset(event.target); // Reset the file input value
                    }}
                    disabled={
                      editRowIndex !== index ||
                      (editRowIndex === index && editRow.file) ||
                      (editRowIndex !== index && row.file)
                    }
                  />

                  <PdfPreviewModal
                    isOpen={showPreviewModal && previewRowIndex === index}
                    onClose={closeModal}
                    file={editRowIndex === index ? editRow.file : row.file}
                    fileName={
                      editRowIndex === index ? editRow.fileName : row.fileName
                    }
                    modifiedTime={
                      editRowIndex === index
                        ? editRow.modifiedTime
                        : row.modifiedTime
                    }
                    row={editRowIndex === index ? editRow : row}
                    scope={scope}
                    uploadedBy={
                      editRowIndex === index
                        ? editRow.uploadedBy
                        : row.uploadedBy
                    }
                    activeRowIndex={index}
                    onDelete={() => handleFileDelete(index)}
                    isCalculated={true}
                  />
                  {iframePreview && row.file && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 rounded-md">
                      <div
                        className="relative bg-white rounded-lg shadow-lg flex"
                        style={{ width: "999px", height: "659px" }}
                      >
                        <div className="flex flex-col w-[711px] h-[650px]">
                          <iframe
                            title="PDF Viewer"
                            src={row.file}
                            width="100%"
                            height="100%"
                            style={{
                              border: "8px solid #F5F5F5",
                              backgroundColor: "white",
                            }}
                          />
                        </div>

                        <div className="flex flex-col w-[288px] px-4 py-4 bg-white gap-5 mt-[5rem]">
                          <h3 className="text-lg font-semibold mb-2">
                            File Information
                          </h3>
                          <div className="text-sm">
                            <p className="font-semibold uppercase">
                              File Name:
                            </p>
                            <p className="truncate">{row.fileName || "N/A"}</p>
                            <br />
                            <p className="font-semibold mt-2 uppercase">
                              File Type:
                            </p>
                            <p>N/A</p>
                            <br />
                            <p className="font-semibold mt-2 uppercase">
                              File Size:
                            </p>
                            <p>N/A</p>
                            <br />
                            <p className="font-semibold mt-2 uppercase">
                              Last Modified:
                            </p>
                            <p>{row?.modifiedTime || "N/A"}</p>
                            <br />
                            <p className="font-semibold mt-2 uppercase">
                              Uploaded By:
                            </p>
                            <p>
                              {row.assignTo
                                ? row.assignTo
                                : "No Username found"}
                            </p>
                            <br />
                          </div>
                        </div>

                        <button
                          onClick={() => setIframePreview(false)}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "20px",
                            background: "transparent",
                            border: "none",
                            color: "black",
                            fontSize: "24px",
                            cursor: "pointer",
                          }}
                          aria-label="Close PDF Viewer"
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </td>

            {showDropdown[index] && (
              <div
                ref={dropdownRef}
                className="absolute bg-white border border-gray-300 rounded shadow w-[80px] right-4 top-2 z-50"
              >
                <div
                  className="flex justify-around items-center bg-white cursor-pointer"
                  onClick={() => handleUpdateClick(index, row)}
                >
                  <EditOutlined className="text-red-600" />
                  Update
                </div>
                <hr className="border border-stone-300 mx-0.5 my-1" />
                <div
                  className="flex justify-around items-center cursor-pointer"
                  onClick={() => handleRowDelete(row.id)}
                >
                  <DeleteOutline className="text-red-600" />
                  Delete
                </div>
              </div>
            )}
          </tr>
        ))}
        <ConfirmationModal
          isOpen={isDeleteConfirmationOpen}
          onClose={closeDeleteConfirmation}
          onConfirm={confirmDelete}
          message="Are you sure you want to delete this row?"
        />
        {/* </div> */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )
  );
}

export default ShowEmission;
