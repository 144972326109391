import React from 'react'

const FileIconExcel = () => {
  return (
    <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.90637 1.91837L10.2869 0.721166C10.4691 0.695132 10.6379 0.821766 10.664 1.00401C10.6662 1.01962 10.6673 1.03537 10.6673 1.05115V14.9491C10.6673 15.1333 10.5181 15.2825 10.334 15.2825C10.3182 15.2825 10.3025 15.2813 10.2869 15.2791L1.90637 14.0819C1.57794 14.035 1.33398 13.7537 1.33398 13.4219V2.57834C1.33398 2.24657 1.57794 1.96529 1.90637 1.91837ZM2.66732 3.15654V12.8437L9.33398 13.7961V2.20416L2.66732 3.15654ZM11.334 12.6668H13.334V3.33348H11.334V2.00015H14.0007C14.3689 2.00015 14.6673 2.29862 14.6673 2.66681V13.3335C14.6673 13.7017 14.3689 14.0001 14.0007 14.0001H11.334V12.6668ZM6.80065 8.00013L8.66732 10.6668H7.06732L6.00065 9.143L4.93398 10.6668H3.33398L5.20065 8.00013L3.33398 5.33348H4.93398L6.00065 6.85727L7.06732 5.33348H8.66732L6.80065 8.00013Z"
      fill="#2A8200"
    />
  </svg>
  )
}

export default FileIconExcel