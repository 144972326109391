import ScopeTable from "./Scope-Table";
import SourceTable from "./Source-Table";
import LocationTable from "./Location-Table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { yearInfo } from "components/data/headerInfo";

const AnalyseEmission = () => {
  const currentUser = useSelector((state) => state.global.loginUser);
  const [analyseData, setAnalyseData] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedCorp, setSelectedCorp] = useState("");
  const [scopeData, setScopeData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const token = useSelector((state) => state.auth.authToken);
  const [selectedYear, setSelectedYear] = useState("2023");

  async function fetchData() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/analyseview?username=${
          currentUser ? currentUser : "mahinder.singh@sustainext.ai"
        }&year=${selectedYear}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const keyToUse = selectedCorp || "";
      const selectedData = data[keyToUse];
      setSelectedCorp(keyToUse);

      if (selectedData) {
        const { source, scope, location } = selectedData;
        const formattedLocation = location.sort((a, b) => b.contribution_scope - a.contribution_scope).map((loc, index) => ({
          sno: String(index + 1),
          location: loc.location_name,
          ageContribution: `${loc.contribution_scope}%`,
          totalemissions: String(loc.total_co2e),
        }));
        const formattedScope = scope.sort((a, b) => b.contribution_scope - a.contribution_scope).map((s, index) => ({
          sno: String(index + 1),
          scope: s.scope_name.charAt(0).toUpperCase() + s.scope_name.slice(1),
          ageContribution: `${s.contribution_scope}%`,
          totalemissions: String(s.total_co2e),
        }));
        const formattedSource = source.sort((a, b) => b.contribution_source - a.contribution_source).map((src, index) => ({
          sno: String(index + 1),
          source: src.source_name,
          ageContribution: `${src.contribution_source}%`,
          totalemissions: String(src.total_co2e),
        }));
        setScopeData(formattedScope);
        setSourceData(formattedSource);
        setLocationData(formattedLocation);
      } else {
        console.log("Organisation not found in the data");
      }

      const resultArray = [];

      Object.keys(data).forEach((key) => {
        resultArray.push({ key: key, value: data[key] });
      });

      setAnalyseData(resultArray);
      console.log(resultArray, data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedCorp, selectedYear]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/orggetonly`,
          { headers }
        );
        console.log("orgs:", response.data);
        setOrganisations(response.data);
      } catch (e) {
        console.log(
          "failed fetching organization",
          process.env.REACT_APP_BACKEND_URL,
          headers
        );
      }
    };

    fetchOrg();
  }, []);

  const [corporates, setCorporates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/corporate/?organization_id=${selectedOrg}`,
          { headers }
        );
        console.log("Corporates:", response.data);
        setCorporates(response.data);
      } catch (e) {
        console.log(
          "failed fetching organization",
          process.env.REACT_APP_BACKEND_URL,
          headers
        );
      }
    };

    fetchData();
  }, [selectedOrg]);

  return (
    <>
      <div className="my-4 pb-5 mx-5 text-left">
        <div className="mb-2 flex items-center py-4 px-3 gap-6">
          <div className="grid grid-cols-1 md:grid-cols-3 w-[80%] mb-4">
            <div className="mr-2">
              <label
                htmlFor="cname"
                className="text-neutral-800 text-[13px] font-normal"
              >
                Select Organization
              </label>
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-neutral-800 text-xs font-normal leading-tight ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={selectedOrg}
                  onChange={(e) => setSelectedOrg(e.target.value)}
                >
                  <option value="">--Select Organization--- </option>
                  {organisations?.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}{" "}
                </select>
              </div>
            </div>
            <div className="mr-2">
              <label
                htmlFor="cname"
                className="text-neutral-800 text-[13px] font-normal"
              >
                Select Corporate
              </label>
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-neutral-500 text-xs font-normal leading-tight ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={selectedCorp}
                  onChange={(e) => setSelectedCorp(e.target.value)}
                >
                  <option value="">--Select Corporate--- </option>
                  {corporates?.map((corp) => (
                    <option key={corp.id} value={corp.name}>
                      {corp.name}
                    </option>
                  ))}{" "}
                </select>
              </div>
            </div>
            <div className="mr-2">
              <label
                htmlFor="cname"
                className="text-neutral-800 text-[13px] font-normal"
              >
                Select Year
              </label>
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-neutral-500 text-xs font-normal leading-tight ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option disabled selected>
                    --Select Year---{" "}
                  </option>
                  {yearInfo.map((year) => (
                    <option value={year.slice(0, 4)} id={year}>
                      {year.slice(0, 4)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mx-8"></div>
        </div>
      </div>
      <div className="mt-8">
        <ScopeTable data={scopeData} />
      </div>
      <div className="mt-8 ">
        <SourceTable data={sourceData} />
      </div>
      <div className="mt-8 ">
        <LocationTable data={locationData} />
      </div>
    </>
  );
};
export default AnalyseEmission;
