import React from "react";

const ReadOnlyTable = ({ data }) => {
  return (
    <table
      className="w-full table-fixed border-separate"
      style={{ borderSpacing: "0 3px" }}
    >
      <thead>
        <tr className="bg-gray-50">
          <th className="pl-3 pr-2 py-2 text-left text-xs font-semibold text-gray-600 w-2/9">
            Category
          </th>
          <th className="pl-3 pr-2 py-2 text-left text-xs font-semibold text-gray-600 w-2/9">
            Sub-Category
          </th>
          <th className="pl-3 pr-2 py-2 text-left text-xs font-semibold text-gray-600 w-2/9">
            Activity
          </th>
          <th className="pl-3 pr-2 py-2 text-left text-xs font-semibold text-gray-600 w-2/9">
            Quantity
          </th>
          <th className="pl-3 pr-2 py-2 text-left text-xs font-semibold text-gray-600 w-1/9">
            Assignee
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className="border-b border-[#f8f9fb] mt-2 text-[#727272]">
            <td className="px-1 py-1.5 text-sm">
              <div className="pl-2 bg-white pr-3 py-1 truncate rounded-md">
                {row.category}
              </div>
            </td>
            <td className="px-1 py-1.5 text-sm">
              <div className="pl-2 bg-white pr-3 py-1 truncate rounded-md">
                {row.subCategory}
              </div>
            </td>
            <td className="px-1 py-1.5 text-sm">
              <div className="pl-2 bg-white pr-3 py-1 truncate rounded-md">
                {row.selectedActivity ? row.selectedActivity : "Not Selected"}
              </div>
            </td>
            <td className="px-1 py-1.5 text-sm">
              <div className="pl-2 bg-white pr-3 py-1 flex justify-between items-center rounded-md">
                <span>Not filled</span>
                <span className="px-3 shadow-lg rounded-lg border text-[#007eef]">Unit</span>
              </div>
            </td>
            <td className="px-1 py-1.5 text-sm">
              <div className="pl-2 bg-white text-[#007eef] pr-3 py-1 truncate shadow-lg rounded-lg">
                {row.assignTo}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ReadOnlyTable;
