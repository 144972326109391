import React, { useState } from "react";
import {
  Factory,
  ParkOutlined,
  DeleteOutline,
  Tungsten,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  Water,
  EmojiNature
} from "@mui/icons-material";
import material from "../../assets/images/material.svg"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
const Aside = ({ activeTab, handleTabClick }) => {
  const [isEnergySectionVisible, setEnergySectionVisible] = useState(false);
  const [isWasteVisible, setWasteVisible] = useState(false);
  const [isWaterVisible, setWaterVisible] = useState(false);
  const [isMaterialsVisible, setMaterialsVisible] = useState(false);
  const toggleEnergySectionVisibility = () => {
    setEnergySectionVisible(!isEnergySectionVisible);
    setWasteVisible(false);
    setMaterialsVisible(false);
    setWaterVisible(false);
  };
  const toggleWasteVisible = () => {
    setWasteVisible(!isWasteVisible);
    setEnergySectionVisible(false);
    setMaterialsVisible(false);
    setWaterVisible(false);
  };
  const toggleWaterVisible = () => {
    setWaterVisible(!isWaterVisible);
    setEnergySectionVisible(false);
    setWasteVisible(false);
    setMaterialsVisible(false);
  };

  const toggleMaterialsVisible = () => {
    setMaterialsVisible(!isMaterialsVisible);
    setEnergySectionVisible(false);
    setWasteVisible(false);
    setWaterVisible(false);

  };
  const handleemssionClick = (option) => {
    // Handle clicking on an option in the Energy dropdown list
    handleTabClick(`${option}`);
    setEnergySectionVisible(false);
    setWasteVisible(false);
    setWaterVisible(false);
    setMaterialsVisible(false);// Hide the section after clicking an option
  };
  return (
    <div className="m-3 ml-2 p-2 border border-r-2 border-b-2 shadow-lg rounded-lg">
      <div className="flex items-start py-4  min-h-[85vh] rounded-lg text-[0.875rem] sm:w-[200px] md:w-[200px] lg:w-[200px] xl:w-[200px] 2xl:w-[200px] 3xl:w-[351px]">
        <div className="flex flex-col w-full font-medium">
          <button className="flex items-center px-4 py-2 -mt-4 mb-8 rounded-none focus:outline-none text-[#727272] font-bold">
            <span className="text-[15px]">Environment</span>
          </button>
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none w-full ${activeTab === "Emissions"
              ? "text-[#007EEF]"
              : "bg-transparent text-[#727272] "
              }`}
            onClick={() => handleemssionClick("Emissions")}
          >
            <Factory className="w-5 h-5 mr-2" />
            <span className="text-left sm:w-[92px] md:w-[92px] lg:w-[92px] xl:w-[92px] 2xl:w-[92px] 3xl:w-[198px]">Emissions</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500 "
                style={{ fontSize: "25px" }}
              />
            </div>
          </button>
          {/* <div
            className={`relative ${
              activeTab.startsWith("Energy") ? "text-[#007EEF] " : ""
            }`}
          >
            <button
              className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none w-full ${activeTab === "Energy consumed inside the organization" ||
                activeTab === "Energy consumption outside of the organization" ||
                activeTab === "Energy Intensity" ||
                activeTab === "Reduction of energy consumption" ||
                activeTab === "Reductions in energy requirements of products and services"
                ? "text-[#007EEF]"
                : "bg-transparent text-[#727272] "
                }`}
              onClick={toggleEnergySectionVisibility}
            >
              <Tungsten className="w-5 h-5 mr-2" />
              <span className="text-left sm:w-[92px] md:w-[92px] lg:w-[92px] xl:w-[92px] 2xl:w-[92px] 3xl:w-[198px]">Energy</span>
              <div className="inset-y-0 -right-2 flex items-center pointer-events-none">
                {isEnergySectionVisible ? (
                  <KeyboardArrowUpOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  <KeyboardArrowDownOutlined
                    className="text-neutral-500"
                    style={{ fontSize: "25px" }}
                  />
                )}
              </div>
            </button>

       
            {isEnergySectionVisible && (
              <div className="bg-white px-2 ml-5 mt-2 border-l-4 border-gray-300">
                <button
                  className={`flex  text-start ml-5 px-2 py-2 mb-2 focus:outline-none w-full ${
                    activeTab === "Energy consumption & energy inside organization"
                      ? "text-[#007EEF] "
                      : "bg-transparent text-[#727272] hover:bg-gray-200 hover:text-gray-500"
                  }`}
                  onClick={() => handleTabClick("Energy consumption & energy inside organization")}
                >
                 
                  <span className="mr-2 ">Energy consumption & energy inside organization</span>
                </button>
                <button
                  className={`flex text-start ml-5 px-2 py-2 mb-2 focus:outline-none w-full ${
                    activeTab === "Energy consumption outside of the organization"
                      ? "text-[#007EEF] "
                      : "bg-transparent text-[#727272] hover:bg-gray-200 hover:text-gray-500"
                  }`}
                  onClick={() => handleTabClick("Energy consumption outside of the organization")}
                >
         
                  <span className="mr-2">Energy consumption outside of the organization</span>
                </button>

                <button
                  className={`flex text-start ml-5 px-2 py-2 mb-2 focus:outline-none w-full ${
                    activeTab === "Energy intensity"
                      ? "text-[#007EEF] "
                      : "bg-transparent text-[#727272] hover:bg-gray-200 hover:text-gray-500"
                  }`}
                  onClick={() => handleTabClick("Energy intensity")}
                >
              
                  <span className="mr-2">Energy intensity</span>
                </button>

                <button
                  className={`flex  text-start ml-5 px-2 py-2 mb-2 focus:outline-none w-full ${
                    activeTab === " Reduction of energy"
                      ? "text-[#007EEF] "
                      : "bg-transparent text-[#727272] hover:bg-gray-200 hover:text-gray-500"
                  }`}
                  onClick={() => handleTabClick(" Reduction of energy")}
                >
                
                  <span className="mr-2"> Reduction of energy</span>
                </button>
              </div>
              
            )}
          </div> */}
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none ${
              activeTab === "Materials"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            // onClick={() => handleTabClick("Materials")}
          >
          <Tungsten className="w-5 h-5 mr-2" />
            <span className="mr-9">Energy</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              {/* <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              /> */}
                  <LockOutlinedIcon
                className="text-neutral-500"
                style={{ fontSize: "1.1rem" }}
              />
            </div>
          </button>
          {/* <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none ${
              activeTab === "Waste"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            onClick={() => handleTabClick("Waste")}
          >
            <DeleteOutline className="w-5 h-5 mr-2" />
            <span className="mr-12">Waste</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              />
            </div>
          </button> */}
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none ${
              activeTab === "Waste"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            // onClick={() => handleTabClick("Waste")}
          >
            <DeleteOutline className="w-5 h-5 mr-2" />
            <span className="mr-12">Waste</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              <LockOutlinedIcon
                className="text-neutral-500"
                style={{ fontSize: "1.1rem" }}
              />
            </div>
          </button>
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none ${
              activeTab === "Materials"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            // onClick={() => handleTabClick("Materials")}
          >
            <img src={material} className="w-5 h-5 mr-2" />
            <span className="mr-8">Materials</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              {/* <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              /> */}
                  <LockOutlinedIcon
                className="text-neutral-500"
                style={{ fontSize: "1.1rem" }}
              />
            </div>
          </button>
          <button
            className={`flex items-center justify-between px-2 py-2 mb-2 focus:outline-none ${
              activeTab === "Water and effluents"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            // onClick={() => handleTabClick("Water and effluents")}
          >
            <Water className="w-5 h-5 mr-2" />
            <span className="mr-8">Water and effluents</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              {/* <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              /> */}
                  <LockOutlinedIcon
                className="text-neutral-500"
                style={{ fontSize: "1.1rem" }}
              />
            </div>
          </button>
          <button
            className={`flex items-center justify-between  px-2 py-2 mb-2 focus:outline-none ${
              activeTab === "Bio diversity"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            // onClick={() => handleTabClick("Bio diversity")}
          >
            <EmojiNature className="w-5 h-5 mr-2" />
            <span className="text-left sm:w-[92px] md:w-[92px] lg:w-[92px] xl:w-[92px] 2xl:w-[92px] 3xl:w-[198px]">Bio diversity</span>
            <div className=" inset-y-0 -right-2 flex items-center pointer-events-none">
              {/* <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "25px" }}
              /> */}
                  <LockOutlinedIcon
                className="text-neutral-500"
                style={{ fontSize: "1.1rem" }}
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Aside;
