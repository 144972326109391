import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { yearInfo } from "components/data/headerInfo";
import { DateRangeOutlined } from "@mui/icons-material";
import TableSidebar from "./TableSidebar";
import DynamicTable from "./customTable";
import { useProSidebar } from "react-pro-sidebar";
import {
  columns1,
  data1,
  columns2,
  data2,
  columns3,
  data3,
  columns4,
  data4,
  columns5,
  data5,
  columns6,
  data6,
  columns7,
  data7,
  columns8,
  data8,
  columns9,
  data9,
  columns10,
  data10,
  columns11,
  data11,
  columns12,
  data12,
  columns13,
  data13,
} from "./data";

const AnalyseEnergy = () => {
  const [organisations, setOrganisations] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedCorp, setSelectedCorp] = useState("");
  const token = useSelector((state) => state.auth.authToken);
  const [selectedYear, setSelectedYear] = useState("2023");
  const { collapsed } = useProSidebar();
  const isGRIBoxOpen = useSelector((state) => state.global.isGRIBoxOpen);

  return (
    <div>
      <div className="mb-2 flex items-center py-4 px-3 gap-6">
        <div className="grid grid-cols-1 md:grid-cols-4 w-[80%] mb-4">
          <div className="mr-2">
            <label
              htmlFor="cname"
              className="text-neutral-800 text-[13px] font-normal"
            >
              Select Organization
            </label>
            <div className="mt-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-4 text-neutral-500 text-xs font-normal leading-tight ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={selectedOrg}
                onChange={(e) => setSelectedOrg(e.target.value)}
              >
                <option value="">--Select Organization--- </option>
                {organisations?.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}{" "}
              </select>
            </div>
          </div>
          <div className="mr-2">
            <label
              htmlFor="cname"
              className="text-neutral-800 text-[13px] font-normal"
            >
              Select Corporate
            </label>
            <div className="mt-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-4 text-neutral-500 text-xs font-normal leading-tight ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={selectedCorp}
                onChange={(e) => setSelectedCorp(e.target.value)}
              >
                <option value="">--Select Corporate--- </option>
                {corporates?.map((corp) => (
                  <option key={corp.id} value={corp.name}>
                    {corp.name}
                  </option>
                ))}{" "}
              </select>
            </div>
          </div>
          <div className="mr-2">
            <label
              htmlFor="cname"
              className="text-neutral-800 text-[13px] font-normal"
            >
              Select Year
            </label>
            <div className="mt-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-4 text-neutral-500 text-xs font-normal leading-tight ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option disabled selected>
                  --Select Year---{" "}
                </option>
                {yearInfo.map((year) => (
                  <option value={year.slice(0, 4)} id={year}>
                    {year.slice(0, 4)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mr-2">
            <label
              htmlFor="cname"
              className="text-neutral-800 text-[13px] font-normal"
            >
              Select Date
            </label>
            <div className="mt-2">
              <div className="border border-neutral-300 w-[208.52px] h-8 px-1.5 bg-white rounded justify-start items-center gap-2.5 inline-flex">
                <div className="grow shrink basis-0 h-[32px] justify-start items-center flex">
                  <div className="grow shrink basis-0 h-7 px-0.5 pt-[4px] pb-[5px] justify-start items-start flex">
                    <div className="text-neutral-500 text-[14px] font-normal font-['Manrope']">
                      30/05/2023 - 05/06/2023
                    </div>
                  </div>
                </div>
                <div className="w-3.5 h-3.5 relative">
                  <div className={`${isGRIBoxOpen ? 'hidden' : 'absolute'} right-0 top-[-5px] `}>
                    <DateRangeOutlined sx={{ fontSize: "18px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-8"></div>
      </div>
      <div className="flex justify-between">
        <div className={`ps-4 ${collapsed ? "w-[81%]" : "w-[78%]"} me-4`}>
          <div className="mb-6">
            <div
              id="fuelFromRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Fuel Consumption within the organisation from Renewable sources
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1a
                </div>
              </div>
            </div>
            <DynamicTable columns={columns1} data={data1} />
          </div>
          <div className="mb-6">
            <div
              id="fuelFromNonRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Fuel Consumption within the organisation from Non-renewable
                sources
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1b
                </div>
              </div>
            </div>
            <DynamicTable columns={columns2} data={data2} />
          </div>
          <div className="mb-6">
            <div
              id="EnergyWithinOrganization"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Energy Consumption Within the organisation
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1e
                </div>
              </div>
            </div>
            <DynamicTable columns={columns3} data={data3} />
          </div>
          <div className="mb-6">
            <div
              id="DirectFromRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Direct Purchased Heating, Cooling, Electricity and Steam from
                renewable sources
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1
                </div>
              </div>
            </div>
            <DynamicTable columns={columns4} data={data4} />
          </div>
          <div className="mb-6">
            <div
              id="DirectFromNonRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Direct Purchased Heating, Cooling, Electricity and Steam from
                non-renewable sources
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1
                </div>
              </div>
            </div>
            <DynamicTable columns={columns5} data={data5} />
          </div>
          <div className="mb-6">
            <div
              id="SelfGenFromRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Self Generated Energy - not consumed or sold (Renewable Energy)
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1
                </div>
              </div>
            </div>
            <DynamicTable columns={columns6} data={data6} />
          </div>
          <div className="mb-6">
            <div
              id="SelfGenFromNonRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Self Generated Energy - not consumed or sold (non-renewable
                Energy)
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1
                </div>
              </div>
            </div>
            <DynamicTable columns={columns7} data={data7} />
          </div>
          <div className="mb-6">
            <div
              id="EnergySoldRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3 flex justify-between items-center"
            >
              <p>
                Energy Sold (Renewable energy)
              </p>
              <div className="w-[70px] h-[26px] p-2 bg-sky-700 bg-opacity-5 rounded-lg justify-center items-center gap-2 inline-flex">
                <div className="text-sky-700 text-[10px] font-semibold font-['Manrope'] leading-[10px] tracking-tight">
                  GRI 302-1d
                </div>
              </div>
            </div>
            <DynamicTable columns={columns8} data={data8} />
          </div>
          <div className="mb-6">
            <div
              id="EnergySoldNonRenewable"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3"
            >
              Energy Sold (non-renewable energy)
            </div>
            <DynamicTable columns={columns9} data={data9} />
          </div>
          <div className="mb-6">
            <div
              id="EnergyOutsideOrganization"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3"
            >
              Energy Consumption outside of the organization
            </div>
            <DynamicTable columns={columns10} data={data10} />
          </div>
          <div className="mb-6">
            <div
              id="EnergyIntensity"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3"
            >
              Energy Intensity
            </div>
            <DynamicTable columns={columns13} data={data13} />
          </div>
          <div className="mb-6">
            <div
              id="ReductionOfEnergy"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3"
            >
              Reduction of energy consumption
            </div>
            <DynamicTable columns={columns11} data={data11} />
          </div>
          <div className="mb-6">
            <div
              id="ReductionInEnergyOfPS"
              className="text-neutral-700 text-[15px] font-normal font-['Manrope'] leading-tight mb-3"
            >
              Reductions in energy requirements of products and services
            </div>
            <DynamicTable columns={columns12} data={data12} />
          </div>
        </div>
        <div
          style={{
            position: `${isGRIBoxOpen ? "unset" : "sticky"}`,
            top: "10rem",
            // zIndex: "1000",
            height: "fit-content",
            backgroundColor: "white"
          }}
          className="me-8 mb-8 -right-2"
        >
          <TableSidebar />
        </div>
      </div>
    </div>
  );
};

export default AnalyseEnergy;
