import React, { useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Location from '../Data-table/Location-Datatable';
import TvIcon from '@mui/icons-material/Tv';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import PrintIcon from '@mui/icons-material/Print';

// const data = [
//   {
//     sno: '1',
//     location: 'ACME factory Austin',
//     ageContribution: '34.67%',
//     totalemissions: '27678.95',
//   },
//   {
//     sno: '2',
//     location: 'Buesnos Aires',
//     ageContribution: '12.87%',
//     totalemissions: '5625.27',
//   },
//   {
//     sno: '3',
//     location: 'Morocco',
//     ageContribution: '6.34%',
//     totalemissions: '2342.26',
//   },
//   {
//     sno: '4',
//     location: 'Orion New York',
//     ageContribution: '4.76%',
//     totalemissions: '1598.45',
//   },
//   {
//     sno: '4',
//     location: 'Acme Factory Albuquerque',
//     ageContribution: '2.98%',
//     totalemissions: '857.45',
//   },

//   // Add more data objects as needed
// ];

const LocationTable = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log('newValue:', newValue);
    setValue(newValue);
  };
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  console.log(data, 'location');

  return (
    <>
      <div className='my-4 mx-5 px-4'>
        <div>
          <h2 className='w-[633px] text-black text-opacity-90 text-[17px] font-bold leading-snug tracking-tight'>
            Top Emission by Location
          </h2>
        </div>
        <div>
          <Location data={data} />
        </div>
      </div>
    </>
  );
};
export default LocationTable;
