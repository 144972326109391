import { createSlice } from '@reduxjs/toolkit';

const energyS1reducer = createSlice({

  name: 'energys1',
  initialState: {
    location: 'Test Location',
    year: '2022',
    month: 'JAN',
    uploadedFile: null,
    // fileRaw:'',
    // fileData: {
    //   rowFile: null, // You can store the selected file here
    // },
    rowsState: [
      { month: 'JAN', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'FEB', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'MAR', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'APR', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'MAY', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'JUN', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'JUL', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'AUG', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'SEP', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'OCT', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'NOV', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
      { month: 'DEC', DirectPurchased: [], ConsumedFuel: [], SelfGenerated: [], EnergySold:[], Standards:[], SourceOfConversion:[]},
    ],

  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setFileData:(state, action)=>{
     state.fileRaw = action.payload;
    },
    setUploadedFile: (state, action) => {
      state.uploadedFile = action.payload;
    },
    setRowsState: (state, action) => {
      const { month, scope, rows } = action.payload;
      const quarterIndex = state.rowsState.findIndex(
        (item) => item.month === month
      );
      if (quarterIndex !== -1) {
        state.rowsState[quarterIndex][scope] = rows.map((r) => ({ ...r }));
      }
    },
  },
});

export const {
  setLocation,
  setYear,
  setMonth,
 setRowsState,
} = energyS1reducer.actions;

export default energyS1reducer.reducer;

export const selectRowsState = (state, scope) =>
  state.emission.rowsState[scope];

export const selectRowsStateNew = (state, month, scope) => {
  const quarterData = state.emission.rowsStateNew.find(
    (data) => data.month === month
  );
  if (quarterData) {
    return quarterData[scope];
  }
  return [];
};

export const selectRowsStateByMonth = (state, month) => {
  const quarterData = state.emission.rowsStateNew.find(
    (data) => data.month === month
  );
  if (quarterData) {
    return quarterData;
  }
  return [];
};



