import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Yearsdata } from "components/data/info/year";
import {useSelector} from 'react-redux';
import { setLocation,setYear,setActiveMonth } from "state/energy/energyS1";

const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const Energyheader = ({ activeMonth, setActiveMonth, onLocationSelect, showSelectLocationMessage, onYeraselect }) => {

  const [locations, setLocations] = useState([]);
  const isMounted = useRef(true);
  const location = useSelector(state=> state.energyS1.location);
  const year = useSelector(state=>state.energyS1.year);
  const month = useSelector(state=>state.energyS1.month);

  const fetchloction = async () => {
    // console.log("user id ", localStorage.getItem("user_id"));
    const stringWithQuotes = localStorage.getItem("authTokens");
    const stringWithoutQuotes = stringWithQuotes.replace(/"/g, "");
    const options = {
      headers: {
        Authorization: 'Token ' + stringWithoutQuotes,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/environment/location/`,
      options
    )
      .then((response) => {
        console.log(response.data);
        setLocations(response.data);

      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchloction();
      isMounted.current = false;
    }
    return () => {
      isMounted.current = false;
    };

  }, []);
  return (
    <>
      <div className="ml-2 mb-5">
        <div className="flex">
          <div className='relative'>

            <select
              className={`border m-0.5 text-sm text-neutral-500 appearance-none pr-24 rounded-md py-2 pl-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 `}
              onChange={(e) => onLocationSelect(e.target.value)}
            > <option value="">Select location</option>
              {locations && locations.map((locations) => (
                <>
                  <option value={locations.location_id}>{locations.location_name}</option>
                </>
              ))}
            </select>
            <div className='absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none'>
              <KeyboardArrowDownOutlinedIcon
                className='text-neutral-500'
                style={{ fontSize: '16px' }}
              />
            </div>

          </div>

          {/* <div className="w-64">
            <select className="rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full">
              <option value="location1">Select location</option>
            </select>
          </div> */}
          <div className='relative ml-3'>
            <select
              className={`border m-0.5 text-sm text-neutral-500 appearance-none pr-32 rounded-md py-2 pl-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 `}
              onChange={(e) => onYeraselect(e.target.value)}
            >
              {Yearsdata.map((years) => (
                <>
                  <option value={years}>{years}</option>
                </>
              ))}
            </select>
            <div className='absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none'>
              <KeyboardArrowDownOutlinedIcon
                className='text-neutral-500'
                style={{ fontSize: '16px' }}
              />
            </div>
          </div>
          {/* <div className="ml-2 w-64">
            <select
              className="rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full"

            >
              <option value="location1">Select year</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>

            </select>
          </div>
          {/* <div className="ml-2 w-64">
            <select className="rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full">

              <option value="location1">Select quarter</option>
            </select>
          </div> */}

        </div>
        <div className="mb-5 ml-2">
          {showSelectLocationMessage && <p className="text-red-500 text-sm">{showSelectLocationMessage}</p>}
        </div>
        <div className="flex justify-between mb-4">
          <div className="flex  bg-[#f7f7f7] py-1 rounded-lg">
            {months.map((month, index) => (
              <button
                key={index}
                className={`text-[12px] border-r mx-1 ${activeMonth === month ? 'bg-white shadow-md rounded-lg' : ''}`}
                onClick={() => setActiveMonth(month)}
              >
                <p className={`text-center ${activeMonth === month ? 'custom-gradient-text' : 'text-[#A1A1A1]'} hover:bg-[#f7f7f7]  py-1 w-[60px] ${index === 0 ? 'rounded-l' : ''} ${index === months.length - 1 ? 'rounded-r' : ''}`}>{month}</p>
              </button>
            ))}
          </div>

        </div>
      </div>
    </>
  );
}

export default Energyheader;