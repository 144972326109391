import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import PdfViewer from "./PdfViewer";
import { useSelector, useDispatch } from "react-redux";
import { setRowsStateNew, selectRowsStateNew } from "state/emissionSlice";

ReactModal.setAppElement("#root");

const PdfPreviewModal = ({
  isOpen,
  onClose,
  file,
  fileName,
  modifiedTime,
  row,
  scope,
  uploadedBy,
  activeRowIndex,
  onDelete,
  isCalculated=false
}) => {
  const [numPages, setNumPages] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const quarter = useSelector((state) => state.emission.quarter);

  const rowsStateNew = useSelector((state) =>
    selectRowsStateNew(state, quarter, scope)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('Updated rowsState:', rowsState[scope]);
  }, [rowsStateNew, scope]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleDelete = () => {
    // console.log(rowsStateNew, Array.isArray(rowsStateNew), scope);
    if (rowsStateNew && Array.isArray(rowsStateNew)) {
      const newRows = rowsStateNew.map((r, index) => {
        if (index === activeRowIndex) {
          console.log("found", r, row);
          const fileInput = document.getElementById(
            `fileInput-${index}-${scope}`
          );
          console.log("file input ", fileInput);
          if (fileInput) {
            console.log("fileinput on delete", fileInput.value, fileInput);
            fileInput.value = "";
          }
          return {
            ...r,
            file: null,
            fileName: "",
            modifiedTime: "",
          };
        }

        return r;
      });
      console.log("newRows:", newRows);
      dispatch(setRowsStateNew({ quarter, scope, rows: newRows }));
      setIsDropdownOpen(false);
      onClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="PDF Preview Modal"
      style={{
        content: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "999px",
          maxHeight: "659px",
          margin: "auto",
          padding: "40px 0",
          position: "relative",
          overflow: "hidden",
          zIndex: 1050,
        },
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.50)",
          zIndex: 1040,
        },
      }}
    >
      {file ? (
        <PdfViewer
          file={file}
          fileName={fileName}
          modifiedTime={modifiedTime}
          row={row}
          scope={scope}
          uploadedBy={uploadedBy}
          handleDelete={isCalculated? onDelete:handleDelete}
          close={onClose}
        />
      ) : (
        <span>File not available</span>
      )}
    </ReactModal>
  );
};

export default PdfPreviewModal;
