import React, { useEffect } from "react";
import Modal from "react-modal";
import ReadOnlyTable from "./ReadOnlyTable";
import { WarningOutlined } from "@mui/icons-material";

const CalculateConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  scopeData,
}) => {

  // useEffect(() => {
  //   const allScopesEmpty = Object.values(scopeData).every(scope => scope.length === 0);

  //   if (allScopesEmpty) {
  //     onConfirm();
  //   }
  // }, [scopeData, onConfirm]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          minWidth: "60vw",
          maxWidth: "75vw",
          minHeight: "70vh",
          height: "auto",
          padding: "20px",
          borderRadius: "8px",
          backgroundColor: "white",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <WarningOutlined
              style={{
                color: "#f98845",
                fontSize: "20px",
                marginRight: "1rem",
              }}
            />
            <h2 className="text-lg font-bold">
              The following rows will not be calculated. Confirm to skip these
              rows.
            </h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {Object.entries(scopeData).map(([scope, data]) => (
          <div key={scope} className="bg-[#f2f8fe] p-4 rounded-lg">
            <h3 className="font-semibold mb-2 text-[#344053]">{scope}</h3>
            <ReadOnlyTable data={data} />
          </div>
        ))}

        <div className="flex justify-end gap-4 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-1 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100"
          >
            Go back
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-1 bg-[#007eef] text-white rounded-md hover:bg-blue-600"
          >
            Confirm & Calculate
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CalculateConfirmationModal;
