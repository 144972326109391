import React, { useState, useEffect } from 'react';

const breakpoints = {
  xs: { width: 500, height: 400 },
  sm: { width: 800, height: 600 },
  md: { width: 1000, height: 700 },
  lg: { width: 1200, height: 800 },
  xl: { width: 1400, height: 900 },
  xxl: { width: 1600, height: 1000 }
};

const Track = () => {
  const [iframeSize, setIframeSize] = useState(breakpoints.md);

  useEffect(() => {
    const getIframeSize = () => {
      const width = window.innerWidth;

      if (width >= 1600) return breakpoints.xxl;
      if (width >= 1400) return breakpoints.xl;
      if (width >= 1200) return breakpoints.lg;
      if (width >= 1000) return breakpoints.md;
      if (width >= 800) return breakpoints.sm;
      return breakpoints.xs;
    };

    const handleResize = () => {
      setIframeSize(getIframeSize());
    };

    handleResize(); 

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='flex justify-center items-center border-2'>
      <iframe
        frameBorder='10'
        width={iframeSize.width}
        height={iframeSize.height}
        // width='1000'
        // height='700'
        src={`${process.env.REACT_APP_TRACK_URL}`}
      ></iframe>
    </div>
  );
};

export default Track;
